<template>
  <div class="alignment-input">
    <label>Выравнивание</label>
    <div class="alignment-input__content">
      <div class="alignment-input__item"
           :class="{'active': value === 'left'}"
           @click="handleClickItem('left')">
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="2" rx="1" fill="#404040"/><rect y="9" width="15.4286" height="2" rx="1" fill="#404040"/><rect y="18" width="24" height="2" rx="1" fill="#404040"/></svg>
      </div>
      <div class="alignment-input__item"
           :class="{'active': value === 'center'}"
           @click="handleClickItem('center')">
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="2" rx="1" fill="#404040"/><rect x="4.28613" y="9" width="15.4286" height="2" rx="1" fill="#404040"/><rect y="18" width="24" height="2" rx="1" fill="#404040"/></svg>
      </div>
      <div class="alignment-input__item"
           :class="{'active': value === 'right'}"
           @click="handleClickItem('right')">
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="2" rx="1" fill="#404040"/><rect x="8.57129" y="9" width="15.4286" height="2" rx="1" fill="#404040"/><rect y="18" width="24" height="2" rx="1" fill="#404040"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlignmentInput",
  model: { prop: 'value', event: 'input' },
  props: {
    value: String
  },
  methods: {
    handleClickItem(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.alignment-input {

  > label {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #121212;
  }

  &__content {
    display: flex;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 52px;
    height: 52px;

    background: #FFFFFF;
    border-radius: 4px;

    cursor: pointer;
    transition: 200ms;

    &.active {
      background: #E0E0E0;
    }

  }

}
</style>
