<template>
  <div class="file-preview">
    <div class="file-preview__content">
      <div class="img-wrapper">
        <img v-if="type === 'PDF'" src="@/assets/img/file-preview/pdf.svg" alt="">
        <img v-else-if="type === 'TTF'" src="@/assets/img/file-preview/ttf.svg" alt="">
      </div>
      <div class="filename">
        {{filename}}
      </div>
    </div>
    <div class="file-preview__actions">
      <button @click="$emit('remove', $event)" type="button">
        <i class="el-icon-close"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilePreview",
  props: {
    type: { String, required: true },
    filename: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.file-preview {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  &__content {
    width: 100%;
    padding: 32px 24px;
    box-sizing: border-box;

    text-align: center;

    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;

    > .img-wrapper {

    }

    > .filename {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      color: #424242;
    }

  }

  &__actions {
    padding-left: 10px;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;

      background: #FF7262;
      border-radius: 2px;
      border: none;

      cursor: pointer;

      i {
        color: #FFFFFF;
      }

    }

  }

}
</style>
