<template>
  <div class="certificates admin-control">
    <div class="admin-control__header">
      <div class="admin-control__header-title">Сертификаты</div>
      <add-button @click.native="handleClickAddCert">Добавить сертификат</add-button>
    </div>
    <slot/>
    <data-tables class="admins-table"
                        :data="certificates"
                        v-loading="loading"
                        :pagination-props="{ pageSizes: [5, 10, 20], background: true }">
      <el-table-column
          label="Наименование"
          width="auto"
          prop="name">
      </el-table-column>
      <el-table-column width="160">
        <template slot-scope="props">
          <div class="admins-table__tools">
            <div @click="editCertificate(props.row)" class="admins-table__edit-item">
              <img src="@/assets/img/admin/icon-edit.svg">
            </div>
            <div @click="makeTestPdf(props.row.id)" class="admins-table__download-item">
              <i class="el-icon-view"/>
            </div>
            <app-popover @click-yes="deleteCertificate(props.row.id)">
              <p slot="title">Вы точно хотите удалить?</p>
              <div slot="icon" class="admins-table__delete-item">
                <img src="@/assets/img/admin/icon-delete.svg">
              </div>
            </app-popover>
          </div>
        </template>
      </el-table-column>
    </data-tables>

    <certificate-popup
        v-if="isShowPopup"
        :certificate="currentCertificate"
        @close="closePopup"/>
    <make-test-certificate-popup
        v-if="isShowMakePopup"
        :certificate-id="currentMakeCertificateId"
        @close="closeMakePopup"/>
  </div>
</template>

<script>
import AddButton from "@/app/admin/components/AddButton";
import CertificatePopup from "@/app/admin/certificates/certificatePopup/CertificatePopup";
import MakeTestCertificatePopup from "@/app/admin/certificates/makeTestCertificatePopup/MakeTestCertificatePopup";
import AppPopover from "@/components/AppPopover";
import {certificatesService} from "@/app/admin/certificates/certificates.service";

export default {
  name: "Certificates",
  components: { AddButton, CertificatePopup, AppPopover, MakeTestCertificatePopup },
  data() {
    return {
      loading: false,
      certificates: [],

      isShowPopup: false,
      currentCertificate: null,

      isShowMakePopup: false,
      currentMakeCertificateId: null
    }
  },
  created() {
    certificatesService.getDefaultFonts()
    this.getCertificates()
  },
  methods: {
    closeMakePopup() {
      this.isShowMakePopup = false
      this.currentMakeCertificateId = null
    },
    closePopup() {
      this.isShowPopup = false
      this.currentCertificate = null
      this.getCertificates()
    },
    deleteCertificate(certificateId) {
      this.loading = true
      certificatesService.deleteCertificate(certificateId)
          .then(() => this.getCertificates())
          .catch(() => this.loading = false)
    },
    editCertificate(certificate) {
      this.currentCertificate = certificate
      this.$nextTick(() => this.isShowPopup = true)
    },
    getCertificates() {
      this.loading = true
      certificatesService.getCertificates()
          .then(data => this.certificates = data)
          .finally(() => this.loading = false)
    },
    makeTestPdf(certificateId) {
      this.currentMakeCertificateId = certificateId
      this.isShowMakePopup = true
    },
    changePagination(query) {
      this.pagination.page = query.page
      this.pagination.limit = query.pageSize

      this.getCertificates()
    },
    handleClickAddCert() {
      this.isShowPopup = true
    }
  }
}
</script>

<style lang="scss" scoped>
.certificates {

}


.admin-control {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 12px;

    &-title {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #2A2A2A;
    }

    &-add-user-btn {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;

      background: #1454F2;
      border: 2px solid #1454F2;
      box-sizing: border-box;
      border-radius: 120px;

      width: 290px;
      height: 55px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
