<template>
  <div class="color-input color-input__unic242">
    <label>Цвет</label>
    <div class="color-input__content">
      <el-color-picker v-model="temp" @change="handleChangeColor"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FontSizeInput",
  model: { prop: 'value', event: 'input' },
  props: {
    value: String
  },
  data() {
    return {
      temp: null
    }
  },
  mounted() {
    this.temp = this.value
  },
  methods: {
    handleChangeColor() {
      this.$emit('input', this.temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-input {
  margin-left: 10px;
  margin-right: 10px;

  > label {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #121212;
  }

  &__content {

    input {
      width: 116px;
      height: 52px;

      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 4px;
    }

  }

}
</style>

<style lang="scss">
.color-input__unic242 {
  .el-color-picker__trigger {
    width: 116px;
    height: 52px;
  }
  .el-color-picker__icon {
    display: none;
  }
}
</style>
