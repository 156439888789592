<template>
  <div class="certificate-popup">
    <base-modal
        :visible="true"
        :title="title"
        :image="require('@/assets/img/admin/dialog-icon-admin.svg')"
        :loading="loading"
        width="540px"
        @submit="handleSave"
        @close="handleClose">
      <div v-loading="loading">

        <div class="certificate-popup__name-input">
          <label>Имя шаблона</label>
          <input type="text" v-model="data.name" placeholder="Введите имя шаблона" :disabled="!!certificate">
        </div>

        <cert-label
            label="Шаблон сертификата"
            text="Вы можете загрузить файл в формате *pdf"/>
        <cert-input
            v-model="data.template"
            :preview="data.pathToTemplate"
            ref="certInput"/>


        <cert-label
            label="Название курса"
            text="Вы можете настроить координаты и шрифт для названия курса"/>

        <coordinates-input v-model="data"
                           :options="{ x: 'courseRectTopleftX', y: 'courseRectTopleftY', w: 'courseRectWidth', h: 'courseRectHeight' }"
                           ref="courseCoordinates"/>

        <font-style-input v-model="data"
                          :preview-ttf="!!data.coursePathToFont"
                          :options="{ ttf: 'courseTtf', defaultFont: 'courseFontName', fontSize: 'courseFontSize', fontColor: 'courseFontColor', fontAlign: 'courseFontAlign' }"/>

        <cert-label
            label="Имя пользователя"
            text="Вы можете настроить координаты и шрифт для пользователя, получившего сертификат"/>

        <coordinates-input v-model="data"
                           :options="{ x: 'userRectTopleftX', y: 'userRectTopleftY', w: 'userRectWidth', h: 'userRectHeight' }"
                           ref="userCoordinates"/>

        <font-style-input v-model="data"
                          :preview-ttf="!!data.userPathToFont"
                          :options="{ ttf: 'userTtf', defaultFont: 'userFontName', fontSize: 'userFontSize', fontColor: 'userFontColor', fontAlign: 'userFontAlign' }"/>


        <cert-label
            label="Дата выдачи курса"
            text="Вы можете настроить координаты и шрифт для даты получения сертификата"/>

        <coordinates-input v-model="data"
                           :options="{ x: 'dateRectTopleftX', y: 'dateRectTopleftY', w: 'dateRectWidth', h: 'dateRectHeight' }"
                           ref="dateCoordinates"/>
        <font-style-input v-model="data"
                          :preview-ttf="!!data.datePathToFont"
                          :options="{ ttf: 'dateTtf', defaultFont: 'dateFontName', fontSize: 'dateFontSize', fontColor: 'dateFontColor', fontAlign: 'dateFontAlign' }"/>


      </div>
    </base-modal>
  </div>
</template>

<script>
import CertLabel from "@/app/admin/certificates/certificatePopup/CertLabel";
import BaseModal from "@/components/BaseModal";
import CertInput from "@/app/admin/certificates/certificatePopup/CertInput";
import CoordinatesInput from "@/app/admin/certificates/certificatePopup/CoordinatesInput";
import FontStyleInput from "@/app/admin/certificates/certificatePopup/fontStyleInput/FontStyleInput";
import {certificatesService} from "@/app/admin/certificates/certificates.service";

export default {
  name: "CertificatePopup",
  model: { prop: 'visible', event: 'change' },
  props: {
    certificate: { type: Object, default: null }
  },
  components: {
    CertLabel,
    BaseModal,
    CertInput,
    CoordinatesInput,
    FontStyleInput
  },
  computed: {
    title() {
      return this.certificate ? 'Редактирование сертификата' : 'Создание сертификата'
    }
  },
  data() {
    return {
      loading: false,
      data: {
        name: '',

        template: null,

        userRectTopleftX: 0, // +
        userRectTopleftY: 0, // +
        userRectWidth: 800, // +
        userRectHeight: 200, // +
        userFontSize: 30, // +
        userFontColor: '#000000', // +
        userFontAlign: 'center', // +
        userTtf: null,
        userFontName: null,

        courseRectTopleftX: 0, // +
        courseRectTopleftY: 300, // +
        courseRectWidth: 800, // +
        courseRectHeight: 200, // +
        courseFontSize: 30, // +
        courseFontColor: '#000000', // +
        courseFontAlign: 'center', // +
        courseTtf: null,
        courseFontName: null,

        dateRectTopleftX: 0,
        dateRectTopleftY: 0,
        dateRectWidth: 800,
        dateRectHeight: 200,
        dateFontSize: 30,
        dateFontColor: "#000000",
        dateFontAlign: "center",
        dateTtf: null,
        dateFontName: null,
      }
    }
  },
  mounted() {
    if (this.certificate) {
      this.data = {
        ...this.certificate,
        template: null,
        userTtf: null,
        courseTtf: null,
        dateTtf: null,
      }
    }
  },
  methods: {
    validate() {
      let error = false

      if (!this.data.name) {
        error = true
        this.$notify.error({ title: 'Ошибка', message: 'Вы не ввели имя шаблона' })
      }

      if (!this.certificate && !this.data.template) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не загрузили шаблон сертификата' })
        }, 100)
      } else if (this.certificate && !this.$refs.certInput._data.removePreview && this.data.template) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не загрузили шаблон сертификата' })
        }, 100)
      }

      if (!this.certificate && !this.data.userTtf && !this.data.userFontName) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не выбрали шрифт названия курса' })
        }, 100)
      }

      if (!this.certificate && !this.data.courseTtf && !this.data.courseFontName) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не выбрали шрифт имени пользователя' })
        }, 100)
      }

      if (!this.certificate && !this.data.dateTtf && !this.data.dateFontName) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не выбрали шрифт даты получения сертификата' })
        }, 100)
      }

      if (!this.data.courseFontSize || !this.data.userFontSize || !this.data.dateFontSize) {
        error = true
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Вы не выбрали размер шрифта' })
        }, 100)
      }


      if (this.$refs.userCoordinates.validate()) {
        error = true
      }
      if (this.$refs.courseCoordinates.validate()) {
        error = true
      }
      if (this.$refs.dateCoordinates.validate()) {
        error = true
      }

      if (error) {
        setTimeout(() => {
          this.$notify.error({ title: 'Ошибка', message: 'Проверьте корректность введенных данных!' })
        }, 100)
      }

      return !error
    },
    handleSave() {
      if (this.validate()) {
        this.loading = true
        if (this.certificate) {
          certificatesService.updateCertificate(this.data)
              .then(() => this.handleClose())
              .finally(() => this.loading = false)
        } else {
          certificatesService.createCertificate(this.data)
              .then(() => this.handleClose())
              .finally(() => this.loading = false)
        }
      }
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate-popup {

  &__name-input {

    label {
      display: block;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: #404040;
    }

    input {
      margin-top: 6px;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #000000;

      width: 100%;
      height: 52px;

      border: 1px solid #E0E0E0;
      border-radius: 4px;

    }

  }

}
</style>
