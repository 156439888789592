<template>
  <div class="ttf-file-input">

    <div v-if="!value" class="ttf-file-input__default-fonts">
      <el-select v-model="defaultFont"
                 placeholder="Выберите шрифт"
                 @change="changeDefaultFont"
                 clearable>
        <el-option
            v-for="fontName in defaultFontNames"
            :key="fontName"
            :label="fontName"
            :value="fontName">
        </el-option>
      </el-select>
    </div>

    <div v-if="!defaultFont && !value" class="ttf-file-input__or">
      или
    </div>

    <div v-if="!defaultFont">
      <file-preview
          v-if="value || isPreview"
          type="TTF"
          :filename="isPreview ?  'file.ttf' : value.name"
          @remove="handleRemoveFile"/>
      <button v-else @click="handleClick" type="button">
        <i class="el-icon-plus"/>
        <span>Загрузить файл TTF</span>
      </button>

      <input type="file"
             style="display: none" ref="input"
             accept=".ttf"
             @change="handleChangeInput">
    </div>

  </div>
</template>

<script>
import FilePreview from "@/app/admin/certificates/certificatePopup/FilePreview";

export default {
  name: "TtfFileInput",
  components: { FilePreview },
  model: { prop: 'value', event: 'input' },
  props: {
    value: { type: File, default: null },
    preview: { type: Boolean, default: false },
    fontName: { type: String }
  },
  computed: {
    isPreview() {
      return this.preview && !this.removePreview
    },
    isDefaultFont() {
      return !!this.defaultFontNames.find(item => item === this.fontName)
    },
    defaultFontNames() {
      return this.$store.state.certificates.defaultFonts
    }
  },
  data() {
    return {
      removePreview: false,

      defaultFont: null
    }
  },
  mounted() {
    if (this.isDefaultFont) {
      this.defaultFont = this.fontName
      this.changeDefaultFont()
    }
  },
  methods: {
    changeDefaultFont() {
      this.$emit('setDefaultFont', this.defaultFont)
    },
    handleClick() {
      this.$refs.input.click()
    },
    handleChangeInput() {
      const file = this.$refs.input.files[0]
      this.$emit('input', file)
      this.$refs.input.value = null
    },
    handleRemoveFile() {
      if (this.isPreview) {
        this.removePreview = true
      }
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.ttf-file-input {

  &__default-fonts {
    width: 100%;
    .el-select {
      width: 100%;
    }
  }

  &__or {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    color: #404040;
  }

  button {
    width: 100%;
    height: 52px;

    background-color: #FFFFFF;
    border: 1px solid #407BFF;
    border-radius: 25px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    text-align: center;

    color: #404040;

    cursor: pointer;
    transition: 200ms;

    &:hover {
      background-color: #407BFF;
      color: #FFFFFF;
    }

    > i {
      margin-right: 10px;
    }

  }

}
</style>
