<template>
  <div class="font-style-input">

    <div class="font-style-input__title">
      <span>Шрифт</span>
    </div>

    <div class="font-style-input__content">

      <ttf-file-input v-model="data[options.ttf]"
                      :preview="previewTtf"
                      :font-name="data[options.defaultFont]"
                      @setDefaultFont="setDefaultFont"/>

      <div style="display: flex">
        <font-size-input v-model="data[options.fontSize]"/>
        <color-input v-model="data[options.fontColor]"/>
        <alignment-input v-model="data[options.fontAlign]"/>
      </div>

    </div>

  </div>
</template>

<script>
import TtfFileInput from "@/app/admin/certificates/certificatePopup/fontStyleInput/TtfFileInput";
import FontSizeInput from "@/app/admin/certificates/certificatePopup/fontStyleInput/FontSizeInput";
import ColorInput from "@/app/admin/certificates/certificatePopup/fontStyleInput/ColorInput";
import AlignmentInput from "@/app/admin/certificates/certificatePopup/fontStyleInput/AlignmentInput";

export default {
  name: "FontStyleInput",
  components: { TtfFileInput, FontSizeInput, ColorInput, AlignmentInput },
  model: { prop: 'data', event: 'input' },
  props: {
    data: Object,
    options: Object,
    previewTtf: { type: Boolean, default: false }
  },
  methods: {
    setDefaultFont(defaultFont) {
      let data = { ...this.data }
      data[this.options.defaultFont] = defaultFont
      this.$emit('input', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.font-style-input {

  &__title {
    margin-top: 20px;
    margin-bottom: 8px;

    span {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      color: #404040;
    }
  }

}
</style>
