<template>
  <div class="cert-label">
    <label>
      <i/>
      {{ label }}
    </label>
    <div v-if="text" class="cert-label__text">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "CertLabel",
  props: {
    label: { type: String, required: true },
    text: { type: String, default: null }
  }
}
</script>

<style lang="scss" scoped>
.cert-label {

  label {
    margin-top: 10px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;

    color: #121212;

    > i {
      margin-right: 10px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #1454F2;
    }
  }

  &__text {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;

    color: #404040;
  }

}
</style>
