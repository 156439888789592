<template>
  <div class="coordinates-input">
    <div class="coordinates-input__title">
      <span>Координаты</span>
    </div>
    <div class="coordinates-input__content">
      <div class="coordinates-input__form-group"
           :class="{'error': errors.x}">
        <label>X</label>
        <div>
          <input type="number" v-model="data[options.x]">
        </div>
      </div>
      <div class="coordinates-input__form-group"
           :class="{'error': errors.y}">
        <label>Y</label>
        <div>
          <input type="number" v-model="data[options.y]">
        </div>
      </div>
      <div class="coordinates-input__form-group"
           :class="{'error': errors.w}">
        <label>Ширина</label>
        <div>
          <input type="number" v-model="data[options.w]">
        </div>
      </div>
      <div class="coordinates-input__form-group"
           :class="{'error': errors.h}">
        <label>Высота</label>
        <div>
          <input type="number" v-model="data[options.h]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoordinatesInput",
  model: { prop: 'data', event: 'input' },
  props: {
    data: Object,
    options: Object
  },
  computed: {
    errors() {
      return {
        x: !this.validX,
        y: !this.validY,
        w: !this.validW,
        h: !this.validH
      }
    },
    validX() {
      return this.data[this.options.x] !== ''
          && this.data[this.options.x] !== null
          && this.data[this.options.x] !== undefined
          && this.data[this.options.x] < 10000
    },
    validY() {
      return this.data[this.options.y] !== ''
          && this.data[this.options.y] !== null
          && this.data[this.options.y] !== undefined
          && this.data[this.options.y] < 10000
    },
    validW() {
      return this.data[this.options.w] !== ''
          && this.data[this.options.w] !== null
          && this.data[this.options.w] !== undefined
          && this.data[this.options.w] > 10
          && this.data[this.options.w] < 10000
    },
    validH() {
      return this.data[this.options.h] !== ''
          && this.data[this.options.h] !== null
          && this.data[this.options.h] !== undefined
          && this.data[this.options.h] > 10
          && this.data[this.options.h] < 10000
    }
  },
  methods: {
    validate() {
      return Object.values(this.errors).find(item => item)
    },
    // validateX() {
    //   if (this.data[this.options.x] === '' || this.data[this.options.x] === null || this.data[this.options.x] === undefined) {
    //     this.errors.x = true
    //     return true
    //   } else {
    //     this.errors.x = false
    //     return false
    //   }
    // },
    // validateY() {
    //   if (this.data[this.options.y] === '' || this.data[this.options.y] === null || this.data[this.options.y] === undefined) {
    //     this.errors.y = true
    //     return true
    //   } else {
    //     this.errors.y = false
    //     return false
    //   }
    // },
    // validateW() {
    //   if (this.data[this.options.w] === '' || this.data[this.options.w] === null || this.data[this.options.w] === undefined) {
    //     this.errors.w = true
    //     return true
    //   } else {
    //     this.errors.w = false
    //     return false
    //   }
    // },
    // validateH() {
    //
    // }
  }
}
</script>

<style lang="scss" scoped>
.coordinates-input {
  margin-top: 20px;

  &__title {
    margin-bottom: 10px;

    span {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      color: #404040;
    }

  }

  &__content {
    display: flex;
  }

  &__form-group {
    margin-left: 7px;
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(3) {
      margin-left: auto;
    }
    &:nth-child(2) {
      margin-right: 0;
    }

    label {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      color: #121212;
    }

    input {
      padding-left: 14px;
      padding-right: 14px;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #1C1C1C;

      width: 108px;
      height: 52px;

      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &.error {
      input {
        border-color: red!important;
      }
    }

  }

}
</style>
