<template>
  <div class="font-size-input">
    <label>Размер</label>
    <div class="font-size-input__content">
      <input type="text" v-model="temp" @input="handleInput">
    </div>
  </div>
</template>

<script>
export default {
  name: "FontSizeInput",
  model: { prop: 'value', event: 'input' },
  props: {
    value: Number
  },
  data() {
    return {
      temp: ''
    }
  },
  mounted() {
    this.temp = this.value
  },
  methods: {
    handleInput() {
      this.$emit('input', Number.parseInt(this.temp))
    }
  }
}
</script>

<style lang="scss" scoped>
.font-size-input {
  margin-right: 10px;

  > label {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #121212;
  }

  &__content {

    input {
      padding-left: 14px;
      padding-right: 14px;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #1C1C1C;

      width: 116px;
      height: 52px;

      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 4px;
    }

  }

}
</style>
