<template>
  <div class="certificate-popup">
    <base-modal
        :visible="true"
        title="Создание тестового сертификата"
        :image="require('@/assets/img/admin/dialog-icon-admin.svg')"
        :loading="loading"
        width="540px"
        @submit="handleSave"
        @close="handleClose">
      <div>

        <div class="certificate-popup__name-input">
          <label>Имя</label>
          <input type="text" v-model="data.name" placeholder="Введите имя">
        </div>
        <div class="certificate-popup__name-input">
          <label>Имя курса</label>
          <input type="text" v-model="data.courseName" placeholder="Введите имя курса">
        </div>
        <div style="padding-top: 10px;padding-bottom: 10px">
          <el-checkbox v-model="data.drawBorder">Отображать рамки текстовых блоков</el-checkbox>
        </div>

      </div>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal";
import {certificatesService} from "@/app/admin/certificates/certificates.service";

export default {
  name: "MakeTestCertificatePopup",
  components: { BaseModal },
  model: { prop: 'visible', event: 'change' },
  props: {
    certificateId: { type: Number, required: true }
  },
  data() {
    return {
      loading: false,
      data: {
        name: 'Иванов Иван Иванович',
        courseName: 'Название курса',
        drawBorder: false
      }
    }
  },
  methods: {
    handleSave() {
      this.loading = true
      certificatesService.makeTestPdf(this.certificateId, this.data)
          .then((link) => {
            this.handleClose()
            window.open(link, '_blank')
          })
          .finally(() => this.loading = false)
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate-popup {

  &__name-input {

    label {
      display: block;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: #404040;
    }

    input {
      margin-top: 6px;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #000000;

      width: 100%;
      height: 52px;

      border: 1px solid #E0E0E0;
      border-radius: 4px;

    }

  }

}
</style>
