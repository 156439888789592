<template>
  <div class="cert-input">

    <file-preview
        v-if="value || isPreview"
        type="PDF"
        :filename="isPreview ? 'template.pdf' : value.name"
        @remove="handleRemoveFile"/>
    <add-button
        v-else
        class="button"
        @click.native="handleClickButton">Выбрать файл PDF</add-button>

    <input
        type="file"
        ref="input"
        accept="application/pdf,application/vnd.ms-excel"
        @change="handleChangeInput">
  </div>
</template>

<script>
import AddButton from "@/app/admin/components/AddButton";
import FilePreview from "@/app/admin/certificates/certificatePopup/FilePreview";

export default {
  name: "CertInput",
  components: { AddButton, FilePreview },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: File, default: null },
    preview: { type: String, default: null }
  },
  computed: {
    isPreview() {
      return this.preview && !this.removePreview
    }
  },
  data() {
    return {
      removePreview: false
    }
  },
  methods: {
    handleClickButton() {
      this.$refs.input.click()
    },
    handleChangeInput() {
      const file = this.$refs.input.files[0]
      this.$emit('change', file)
      this.$refs.input.value = null
    },
    handleRemoveFile() {
      if (this.isPreview) {
        this.removePreview = true
      }
      this.$emit('change', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.cert-input {

  > .button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  > input {
    display: none;
  }

}
</style>
